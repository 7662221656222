var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box d-flex flex-column flex-1" }, [
    _vm.isIphone
      ? _c("div", { staticClass: "iphoneBox d-flex a-center j-center" }, [
          _c("div", { staticClass: "popBox" }, [
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.isIphoneText)),
            ]),
          ]),
        ])
      : _vm._e(),
    !_vm.isLoading && !_vm.isMobile
      ? _c(
          "div",
          { staticClass: "mainPC flex-1", staticStyle: { width: "1200px" } },
          [
            _c("img", {
              staticClass: "bg_activity",
              attrs: {
                src: require("../../assets/imgs/activity/202405/bg_PC_activity.jpg"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "scrollView" }, [
                _c(
                  "div",
                  { staticClass: "payList" },
                  _vm._l(_vm.payList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "item d-flex a-center" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "left d-flex flex-wrap",
                            class: { filter10: !item.isLock },
                          },
                          _vm._l(item.items, function (goods, gIndex) {
                            return _c(
                              "div",
                              {
                                key: gIndex,
                                staticClass:
                                  "giveGoods d-flex flex-column a-center j-center",
                              },
                              [
                                goods.svgaUrl
                                  ? _c("svgaCom", {
                                      staticClass: "imgUrl",
                                      attrs: {
                                        loop: 1,
                                        svgaUrl: goods.svgaUrl,
                                        id: `activitySvga_${index}_${gIndex}`,
                                      },
                                    })
                                  : _c("img", {
                                      staticClass: "imgUrl",
                                      attrs: {
                                        src:
                                          goods.iconUrl +
                                          "?x-oss-process=image/resize,w_100,m_lfit",
                                        alt: "",
                                      },
                                    }),
                                _c("div", { staticClass: "goodsName" }, [
                                  _vm._v(_vm._s(goods.name)),
                                ]),
                                goods.days
                                  ? _c("div", { staticClass: "dayText" }, [
                                      _vm._v(_vm._s(goods.days) + "天"),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        index == 0
                          ? _c("div", { staticClass: "descText" }, [
                              _vm._v("*骑士以下直升、骑士以上用户3000贵族经验"),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass:
                              "moneyBox d-flex flex-column a-center j-center flex-1",
                            class: { filter10: !item.isLock },
                          },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("累充" + _vm._s(item.money) + "元"),
                            ]),
                            _c("div", { staticClass: "progressBar" }, [
                              _c("div", {
                                staticClass: "active",
                                style: {
                                  width:
                                    (item.payMoney * 100) / item.money > 100
                                      ? "100%"
                                      : (item.payMoney * 100) / item.money +
                                        "%",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "payMoney" }, [
                              _vm._v(
                                _vm._s(item.payMoney) +
                                  "/" +
                                  _vm._s(item.money) +
                                  "元"
                              ),
                            ]),
                          ]
                        ),
                        _c("div", {
                          class: [
                            "btn",
                            { filter10: !item.isLock },
                            { btn0: item.status == 0 },
                            { btn1: item.status == 1 },
                            { btn2: item.status == 2 },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.btnHandle(item)
                            },
                          },
                        }),
                        !item.isLock
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "mask d-flex flex-column a-center j-center",
                              },
                              [
                                _c("img", {
                                  staticClass: "icon_lock",
                                  attrs: {
                                    src: require("../../assets/imgs/activity/icon_lock.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "cardBox" }, [
                  _c("img", {
                    ref: "equity",
                    staticClass: "title",
                    attrs: {
                      id: "equity",
                      src: require("../../assets/imgs/activity/icon_PC_equityTitle.png"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    staticClass: "bg_equity",
                    attrs: {
                      src: require("../../assets/imgs/activity/bg_equity.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("div", { staticClass: "cardBox" }, [
                  _c("img", {
                    ref: "rule",
                    staticClass: "title",
                    attrs: {
                      id: "rule",
                      src: require("../../assets/imgs/activity/icon_PC_ruleTitle.png"),
                      alt: "",
                    },
                  }),
                  _vm._m(0),
                ]),
                _vm._m(1),
              ]),
              _c(
                "div",
                {
                  staticClass: "equityBtn",
                  on: {
                    click: function ($event) {
                      return _vm.scrollChange("equity")
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: require("../../assets/imgs/activity/202405/icon_equity.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "ruleBtn",
                  on: {
                    click: function ($event) {
                      return _vm.scrollChange("rule")
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: require("../../assets/imgs/activity/202405/icon_rule.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "goHomeBtn", on: { click: _vm.openHomeHandle } },
              [
                _c("img", {
                  staticClass: "icon_goHome",
                  attrs: {
                    src: require("../../assets/imgs/activity/icon_goHome.png"),
                    alt: "",
                  },
                }),
              ]
            ),
            _vm.isShowReceiveSuccess
              ? _c("div", { staticClass: "receiveSuccessBox" }, [
                  _c("div", { staticClass: "mask" }),
                  _c("img", {
                    staticClass: "bg_receiveSuccess",
                    attrs: {
                      src: require("../../assets/imgs/activity/202405/bg_PC_receiveSuccess.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "receiveList d-flex flex-wrap" },
                    _vm._l(_vm.receiveList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass:
                            "receiveItem d-flex flex-column a-center j-center",
                        },
                        [
                          item.svgaUrl
                            ? _c("svgaCom", {
                                staticClass: "imgUrl",
                                attrs: {
                                  loop: 1,
                                  svgaUrl: item.svgaUrl,
                                  id: `receiveSvga_${index}`,
                                },
                              })
                            : _c("img", {
                                staticClass: "imgUrl",
                                attrs: {
                                  src:
                                    item.iconUrl +
                                    "?x-oss-process=image/resize,w_100,m_lfit",
                                  alt: "",
                                },
                              }),
                          _c("div", { staticClass: "goodsName" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          item.days
                            ? _c("div", { staticClass: "dayText" }, [
                                _vm._v(_vm._s(item.days) + "天"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("div", {
                    staticClass: "btnConfirm",
                    on: { click: _vm.closeReceiveSuccess },
                  }),
                ])
              : _vm._e(),
            _vm.isNoActivity
              ? _c(
                  "div",
                  { staticClass: "noActivityBox d-flex a-center j-center" },
                  [
                    _c("div", { staticClass: "mask" }),
                    _c("div", { staticClass: "popBox" }, [
                      _c("div", { staticClass: "d-flex a-center j-center" }, [
                        _c("img", {
                          staticClass: "icon_shalou",
                          attrs: {
                            src: require("../../assets/imgs/activity/icon_shalou.png"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.noActivityMessage)),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "btnConfirm",
                        on: {
                          click: function ($event) {
                            _vm.isNoActivity = !_vm.isNoActivity
                          },
                        },
                      }),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    !_vm.isLoading && _vm.isMobile
      ? _c("div", { staticClass: "main" }, [
          _c("img", {
            staticClass: "bg_activity",
            attrs: {
              src: require("../../assets/imgs/activity/202405/bg_H5_activity.png"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "scrollView" }, [
            _c(
              "div",
              { staticClass: "payList" },
              _vm._l(_vm.payList, function (item, index) {
                return _c("div", { key: index, staticClass: "item" }, [
                  _c(
                    "div",
                    {
                      staticClass: "top d-flex a-center j-sb",
                      class: { filter10: !item.isLock },
                    },
                    [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("累充" + _vm._s(item.money) + "元"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "progressBar d-flex a-center j-center" },
                        [
                          _c("div", {
                            staticClass: "active",
                            style: {
                              width:
                                (item.payMoney * 100) / item.money > 100
                                  ? "100%"
                                  : (item.payMoney * 100) / item.money + "%",
                            },
                          }),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(item.payMoney) +
                                "/" +
                                _vm._s(item.money) +
                                "元"
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "goodsList d-flex a-center j-center flex-wrap",
                      class: { filter10: !item.isLock },
                    },
                    _vm._l(item.items, function (goods, gIndex) {
                      return _c(
                        "div",
                        {
                          key: gIndex,
                          staticClass:
                            "goods d-flex flex-column a-center j-center",
                          style: (gIndex + 1) % 4 == 0 ? "margin-right:0" : "",
                        },
                        [
                          goods.svgaUrl
                            ? _c("svgaCom", {
                                staticClass: "imgUrl",
                                attrs: {
                                  svgaUrl: goods.svgaUrl,
                                  id: `activitySvga_${index}_${gIndex}`,
                                },
                              })
                            : _c("img", {
                                staticClass: "imgUrl",
                                attrs: {
                                  src:
                                    goods.iconUrl +
                                    "?x-oss-process=image/resize,w_100,m_lfit",
                                  alt: "",
                                },
                              }),
                          _c("div", { staticClass: "goodsName" }, [
                            _vm._v(_vm._s(goods.name)),
                          ]),
                          goods.days
                            ? _c("div", { staticClass: "dayText" }, [
                                _vm._v(_vm._s(goods.days) + "天"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("div", {
                    class: [
                      "btn",
                      { filter10: !item.isLock },
                      { btn0: item.status == 0 },
                      { btn1: item.status == 1 },
                      { btn2: item.status == 2 },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.btnHandle(item)
                      },
                    },
                  }),
                  !item.isLock
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mask d-flex flex-column a-center j-center",
                        },
                        [
                          _c("img", {
                            staticClass: "icon_lock",
                            attrs: {
                              src: require("../../assets/imgs/activity/icon_lock.png"),
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  index == 0
                    ? _c("div", { staticClass: "descText" }, [
                        _vm._v("*骑士以下直升、骑士以上用户3000贵族经验"),
                      ])
                    : _vm._e(),
                ])
              }),
              0
            ),
            _vm._m(2),
            _vm._m(3),
            _vm._m(4),
          ]),
          _vm.isShowReceiveSuccess
            ? _c(
                "div",
                {
                  staticClass:
                    "receiveSuccessBox d-flex flex-column a-center j-center",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "topBox" }, [
                    _c("img", {
                      staticClass: "bg_receiveSuccess",
                      attrs: {
                        src: require("../../assets/imgs/activity/202405/bg_H5_receiveSuccess.png"),
                        alt: "",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "receiveList d-flex flex-wrap a-center j-center",
                      },
                      _vm._l(_vm.receiveList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass:
                              "receiveItem d-flex flex-column a-center j-center",
                          },
                          [
                            item.svgaUrl
                              ? _c("svgaCom", {
                                  staticClass: "imgUrl",
                                  attrs: {
                                    loop: 1,
                                    svgaUrl: item.svgaUrl,
                                    id: `receiveSvga_${index}`,
                                  },
                                })
                              : _c("img", {
                                  staticClass: "imgUrl",
                                  attrs: {
                                    src:
                                      item.iconUrl +
                                      "?x-oss-process=image/resize,w_100,m_lfit",
                                    alt: "",
                                  },
                                }),
                            _c("div", { staticClass: "goodsName" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            item.days
                              ? _c("div", { staticClass: "dayText" }, [
                                  _vm._v(_vm._s(item.days) + "天"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("div", {
                    staticClass: "btnConfirm",
                    on: { click: _vm.closeReceiveSuccess },
                  }),
                ]
              )
            : _vm._e(),
          _vm.isNoActivity
            ? _c(
                "div",
                { staticClass: "noActivityBox d-flex a-center j-center" },
                [
                  _c("div", { staticClass: "popBox" }, [
                    _c("div", { staticClass: "d-flex a-center j-center" }, [
                      _c("img", {
                        staticClass: "icon_shalou",
                        attrs: {
                          src: require("../../assets/imgs/activity/icon_shalou.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.noActivityMessage)),
                      ]),
                    ]),
                    _c("div", {
                      staticClass: "btnConfirm",
                      on: {
                        click: function ($event) {
                          _vm.isNoActivity = !_vm.isNoActivity
                        },
                      },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ruleBox" }, [
      _c("p", [
        _vm._v("1. 活动期间充值达到相对应档位金额即可成功领取该档对应奖励；"),
      ]),
      _c("p", [_vm._v("2. 充值活动时效期内累积，每档只能领取一次；")]),
      _c("p", [_vm._v("3. 充值奖励显示5档，每达到一档后往后解锁新的一档；")]),
      _c("p", [
        _vm._v("4. 所有充值都计入充值累积，随心币兑换随心钻不计入充值累积；"),
      ]),
      _c("p", [
        _vm._v(
          "5. 活动自选套装，奖励的靓号，定制头像框，定制气泡，点击领取后联系客服方可兑换，靓号可以自用及赠送，领取有效期为活动结束日后20天内。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footerDesc" }, [
      _c("p", [
        _vm._v(
          "在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；"
        ),
      ]),
      _c("p", [_vm._v("如有疑问请联系在线客服或客服QQ: 1058127957进行咨询")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cardBox" }, [
      _c("img", {
        staticClass: "title",
        attrs: {
          id: "equity",
          src: require("../../assets/imgs/activity/icon_equityTitle.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "equityBox d-flex" }, [
        _c("img", {
          staticClass: "iconLeft",
          attrs: {
            src: require("../../assets/imgs/activity/bg_H5_equityLeft.png"),
            alt: "",
          },
        }),
        _c("div", { staticClass: "rightBox" }, [
          _c("img", {
            staticClass: "iconRight",
            attrs: {
              src: require("../../assets/imgs/activity/bg_H5_equityRight.png"),
              alt: "",
            },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cardBox" }, [
      _c("img", {
        staticClass: "title",
        attrs: {
          id: "rule",
          src: require("../../assets/imgs/activity/icon_ruleTitle.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "ruleBox" }, [
        _c("p", [
          _vm._v("1. 活动期间充值达到相对应档位金额即可成功领取该档对应奖励；"),
        ]),
        _c("p", [_vm._v("2. 充值活动时效期内累积，每档只能领取一次；")]),
        _c("p", [_vm._v("3. 充值奖励显示5档，每达到一档后往后解锁新的一档；")]),
        _c("p", [
          _vm._v("4. 所有充值都计入充值累积，随心币兑换随心钻不计入充值累积；"),
        ]),
        _c("p", [
          _vm._v(
            "5. 活动自选套装，奖励的靓号，定制头像框，定制气泡，点击领取后联系客服方可兑换，靓号可以自用及赠送，领取有效期为活动结束日后20天内。"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footerDesc" }, [
      _c("p", [
        _vm._v(
          "在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；"
        ),
      ]),
      _c("p", [_vm._v("如有疑问请联系在线客服或客服QQ: 1058127957进行咨询")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }